var waveParticle;
var waveParticleDetail;

function Wave() {
	/** Original setting  */
	var WIDTH = window.innerWidth;
	var HEIGHT
	var HEIGHT_EXTRA_TOP = 50;

	

	/** Current Settomg Wave settings */
	var DENSITY = 1.4;
	var FRICTION = 1.3;
	var MOUSE_PULL = 0.002; // The strength at which the mouse pulls particles within the AOE
	var AOE = 60; // Area of effect for mouse pull
	var DETAIL = waveParticleDetail = Math.round(WIDTH / 20); // The number of particles used to build up the wave
	var TWITCH_INTERVAL = 100; //


	var ms = {
		x: 0,
		y: 0
	}; // Mouse speed
	var mp = {
		x: 0,
		y: 0
	}; // Mouse position

	var waveType = "fill";
	var HEIGHT_WATER_LEVEL = 1;

	var plusminus = 0;
	var dirUPDown = true;

	var particles;
	var parentPosition;

	var waveSVG;
	var svgDString = "";

	var twitchInc = 0;
	var isRender = true;
	var pathTag;

	/**
	 * Constructor.
	 */
	this.initialize = function (svgID) {
		var svgID = 'waveSVG';
		if (Modernizr.svg && Modernizr.requestanimationframe) {
			//console.log("supported");
		} else {
			return;
		}
		waveSVG = document.getElementById(svgID);
		pathTag = document.getElementById("waveSVGPath");



		particles = waveParticle = [];

		// Generate our wave particles
		for (var i = 0; i < DETAIL + 1; i++) {
			particles.push({
				x: WIDTH / (DETAIL - 4) * (i - 2), // Pad by two particles on each side
				y: HEIGHT_WATER_LEVEL * 0.5,
				original: {
					x: 0,
					y: HEIGHT_WATER_LEVEL * 0.5
				},
				velocity: {
					x: 0,
					y: Math.random() * 3
				}, // Random for some initial movement in the wave
				force: {
					x: 0,
					y: 0
				},
				mass: 7.5
			});
		}


		$("html").mousemove(MouseMove);
		$(window).bind('resize', this.resizeSVG);

		//twitchInterval = setInterval(Twitch, TWITCH_INTERVAL);

		this.resizeSVG();
		requestAnimationFrame(TimeUpdate);
	};
	this.stopRender = function () {
		isRender = false;
	}

	this.startRender = function () {
		isRender = true;
	}

	/**
	 * Inserts a random impulse to keep the wave moving.
	 * Impulses are only inserted if the mouse is not making
	 * quick movements.
	 */
	function Twitch() {
		if (ms.x < 6 || ms.y < 6) {
			var forceRange = 9; // -value to +value
			//InsertImpulse( Math.random() * WIDTH, (Math.random()*(forceRange*2)-forceRange ) );
			InsertImpulse(
				Math.random() * WIDTH,
				//Math.random() * (forceRange / 2) + forceRange / 2,
				Math.random() * (forceRange / 4) + forceRange
			);
		}
	}

	/**
	 * Inserts an impulse in the wave at a specific position.
	 * 
	 * @param positionX the x coordinate where the impulse
	 * should be inserted
	 * @param forceY the force to insert
	 */
	function InsertImpulse(positionX, forceY) {
		var particle =
			particles[Math.round(positionX / WIDTH * particles.length)];

		if (particle) {
			particle.force.y += forceY;
		}
	}

	/**
	 * 
	 */
	function TimeUpdate(e) {
		if (isRender == false) {
			requestAnimationFrame(TimeUpdate);
			return;
		}
		twitchInc++;
		//console.log('HEIGHT');

		svgDString = "M" + particles[0].x + "," + (particles[0].y + HEIGHT_EXTRA_TOP);

		var len = particles.length;
		var i;

		var current, previous, next;

		for (i = 0; i < len; i++) {
			current = particles[i];
			previous = particles[i - 1];
			next = particles[i + 1];

			if (previous && next) {
				var forceY = 0;

				forceY += -DENSITY * (previous.y - current.y);

				forceY += DENSITY * (current.y - next.y);
				forceY += DENSITY / 15 * (current.y - current.original.y);

				current.velocity.y +=
					-(forceY / current.mass) + current.force.y;
				current.velocity.y /= FRICTION;
				current.force.y /= FRICTION;
				current.y += current.velocity.y;

				var distance = DistanceBetween(mp, current);

				if (distance < AOE_new) {
					var distance = DistanceBetween(mp, {
						x: current.original.x,
						y: current.original.y
					});

					ms.x = ms.x * 0.98;
					ms.y = ms.y * 0.98;

					current.force.y += MOUSE_PULL * (1 - distance / AOE_new) * ms.y;
				}

				// cx, cy, ax, ay
				var prevX = previous.x;
				var prevY = previous.y;
				var midX = previous.x + (current.x - previous.x) / 2;
				var mixY = previous.y + (current.y - previous.y) / 2;

				svgDString +=
					" Q" + prevX + "," + (prevY + HEIGHT_EXTRA_TOP) + " " + midX + "," + (mixY + HEIGHT_EXTRA_TOP);

			}

		}


		svgDString += " L" + particles[particles.length - 1].x + "," + particles[particles.length - 1].y;

		svgDString += " L" + WIDTH + "," + (HEIGHT + HEIGHT_EXTRA_TOP);

		svgDString += " L" + 0 + "," + (HEIGHT + HEIGHT_EXTRA_TOP);

		svgDString += " L" + particles[0].x + "," + particles[0].y;


		pathTag.setAttribute("d", svgDString);
		requestAnimationFrame(TimeUpdate);
		if (twitchInc > TWITCH_INTERVAL) {
			twitchInc = 0;
			Twitch();
		}
	}

	function getPosition(element) {
		var xPosition = 0;
		var yPosition = 0;
		while (element) {
			xPosition +=
				element.offsetLeft - element.scrollLeft + element.clientLeft;
			yPosition +=
				element.offsetTop - element.scrollTop + element.clientTop;
			element = element.offsetParent;
		}
		return {
			x: xPosition,
			y: yPosition
		};
	}

	var ExtremWaveArea = 100;

	function MouseMove(e) {

		var layerX = e.pageX;
		var layerY = (e.pageY - HEIGHT);

		/*var layerX = e.clientX;
		var layerY = (e.clientY-HEIGHT);*/

		//if (layerY > ExtremWaveArea || layerY < -ExtremWaveArea) {
		if (dirUPDown) {
			plusminus += 7;
			if (plusminus > ExtremWaveArea) {
				dirUPDown = false;
			}
		} else {
			plusminus -= 7;
			if (plusminus < -ExtremWaveArea) {
				dirUPDown = true;
			}
		}
		layerY = plusminus;
		//}
		ms.x = Math.max(Math.min(layerX - mp.x, 40), -40);
		ms.y = Math.max(Math.min(layerY - mp.y, 40), -40);

		mp.x = layerX;
		mp.y = layerY;
	}

	/**
	 * 
	 */
	this.resizeSVG = function (e) {
		if (Modernizr.svg && Modernizr.requestanimationframe) {
			//console.log("supported");
		} else {
			return;
		}
		$("#footerHolder").css({'background':'none'});
		$("#footerHolder").css({paddingTop:'40px', paddingBottom:'50px'});

		WIDTH = $("#footerHolder").innerWidth();
		HEIGHT = $("#footerHolder").innerHeight();
		AOE_new = AOE * (WIDTH / 1000);
		
		
		waveSVG.setAttribute("width", WIDTH + "px");
		waveSVG.setAttribute("height", (HEIGHT + HEIGHT_EXTRA_TOP) + "px");

		for (var i = 0; i < DETAIL + 1; i++) {
			particles[i].x = WIDTH / (DETAIL - 4) * (i - 2);
			particles[i].y = HEIGHT_WATER_LEVEL * 0.5;
			particles[i].original.x = 0;
			particles[i].original.y = HEIGHT_WATER_LEVEL * 0.5;
		}

	}

	/**
	 * 
	 */
	function DistanceBetween(p1, p2) {
		var dx = p2.x - p1.x;
		var dy = p2.y - p1.y;
		return Math.sqrt(dx * dx + dy * dy);
	}
}
var elasticBubbleArray = [];

function destroyAllElasticBubbles() {
   if (isMobile.any()) {} else if (Modernizr.svg && Modernizr.requestanimationframe) {
      for (var i = 0; i < elasticBubbleArray.length; i++) {
         var obj = elasticBubbleArray[i];
         obj.destroy();
      }
   }
}

function setupElasticBubble(ele) {
   if (isMobile.any()) {} else if (Modernizr.svg && Modernizr.requestanimationframe) {
      var bubble = new WaterBubble();
      bubble.initialize(ele);
      elasticBubbleArray.push(bubble);
      return bubble;
   }
   return null;
}

function setPropertyToAll(paramObj) {
   if (isMobile.any()) {} else if (Modernizr.svg && Modernizr.requestanimationframe) {
      for (var i = 0; i < elasticBubbleArray.length; i++) {
         var obj = elasticBubbleArray[i];
         obj.setProperty(paramObj);
      }
   }
}

function callResizeOnAllBubble(paramObj) {
   if (isMobile.any()) {} else if (Modernizr.svg && Modernizr.requestanimationframe) {
      for (var i = 0; i < elasticBubbleArray.length; i++) {
         var obj = elasticBubbleArray[i];
         obj.resizeSVG();
      }
   }
}

function setWhiteBorderActiveAll(paramObj) {
   if (isMobile.any()) {} else if (Modernizr.svg && Modernizr.requestanimationframe) {
      for (var i = 0; i < elasticBubbleArray.length; i++) {
         var obj = elasticBubbleArray[i];
         obj.setWhiteBorderActive(paramObj);
      }
   }
}

function getWaterBubbleByRefStr(str) {
   if (isMobile.any()) {} else if (Modernizr.svg && Modernizr.requestanimationframe) {
      for (var i = 0; i < elasticBubbleArray.length; i++) {
         var obj = elasticBubbleArray[i];
         if (obj.refStr == str && obj.refStr != "") {
            return obj;
         }
      }
   }
   return null;
}

var waterBubbleTimeUpdateArray = [];

function globleTimeUpdate() {
   for (var i = 0; i < waterBubbleTimeUpdateArray.length; i++) {
      waterBubbleTimeUpdateArray[i]();
   }
   requestAnimationFrame(globleTimeUpdate);
}

function getUrlVars() {
   var vars = {};
   var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
   });
   return vars;

}

/*function setWhiteBorderWidthAll(paramObj) {
    if (isMobile.any()) {} else if (Modernizr.svg && Modernizr.requestanimationframe) {
        for (var i = 0; i < elasticBubbleArray.length; i++) {
            var obj = elasticBubbleArray[i];
            obj.setWhiteBorderWidth(paramObj);
        }
    }
}*/

function WaterBubble() {
   //-- default
   var gutter = 30;
   //-- default - minimum value 2 is more intencity
   //-- maximum value more than 2 will low intencity
   var randomIntencity = 3;
   //-- default - random move speed
   var randomMoveSpeed = 3;
   //-- default
   var mouseRadius = gutter * 1.8;
   //
   //
   var whiteBorderWidth = 30;
   //
   var isDestroy = false;
   //-- default
   var segment = 8;
   //
   var is360 = false;
   //
   this.refStr = "";
   //
   var numOfLookInDegree = 8;
   var eachLookInDegree = 360 / numOfLookInDegree;
   //
   var isMoveOnlyOver = true;
   var tempExtraGutter;


   var isWhiteBorderActive = false;

   this.stopRender = function () {

   }

   this.startRender = function () {

   }

   this.setWhiteBorderActive = function (obj) {
      isWhiteBorderActive = obj.tf == undefined ? false : obj.tf;
   }
   //----------------------------------------------------



   var svgDString;
   var particles;
   var parentElement;
   var svgElement;
   var svgPath;
   var pathSVGWhite;
   var isRender;
   var svgWidth;
   var svgHeigth;
   var svgHalfWH;
   var randomMoveArea;
   var mouseObj = {
      x: 0,
      y: 0
   };
   var lookObj = {
      degree: 0
   };
   var imagesObjArr = [];
   var imagesTagArr;
   var preserveAspectRatio;
   var isInWindow = false;


   this.initialize = function (paramObj) {
      var This = this;
      if (Modernizr.svg && Modernizr.requestanimationframe) {} else {
         return;
      }

      segment = paramObj.segment != undefined ? paramObj.segment : segment;
      gutter = paramObj.gutter != undefined ? paramObj.gutter : gutter;
      randomIntencity = paramObj.randomIntencity != undefined ? paramObj.randomIntencity : randomIntencity;
      randomMoveSpeed = paramObj.randomMoveSpeed != undefined ? paramObj.randomMoveSpeed : randomMoveSpeed;
      mouseRadius = paramObj.mouseRadius != undefined ? paramObj.mouseRadius : mouseRadius;
      isMoveOnlyOver = paramObj.isMoveOnlyOver != undefined ? paramObj.isMoveOnlyOver : isMoveOnlyOver;
      is360 = paramObj.is360 != undefined ? paramObj.is360 : is360;
      whiteBorderWidth = paramObj.whiteBorderWidth != undefined ? paramObj.whiteBorderWidth : whiteBorderWidth;
      this.refStr = paramObj.refStr != undefined ? paramObj.refStr : this.refStr;




      parentElement = $(paramObj.ele);
      //$('.bubbleImgHolder').css({'overflow':'visible'});
      preserveAspectRatio = parentElement.attr("preserveAspectRatio");
      preserveAspectRatio = preserveAspectRatio;
      parentElement.css({
         'overflow': 'visible'
      });

      var orgImg = parentElement.find('.bubbleOrgImg');
      orgImg.css({
         display: "none"
      });

      var ranId = Math.round(Math.random() * 10000);
      var svgStr = '';
      svgStr += '<svg class="bubble" preserveAspectRatio="none" width="100%" height="100%">';
      svgStr += '<clipPath id="myClip_' + ranId + '">';
      svgStr += '<path class="pathSVG" d="M62.45,165.18"></path>';
      svgStr += '</clipPath>';

      svgStr += '<path class="pathSVGWhite" stroke-width="40" stroke-linecap="round" stroke="white" fill="white" d=""></path>';
      svgStr += '<image lookId="0" preserveAspectRatio="none" clip-path="url(#myClip_' + ranId + ')" width="100%" height="100%" xlink:href="' + orgImg.attr('src') + '" />';

      if (is360) {
         for (var i = 1; i <= numOfLookInDegree; i++) {
            svgStr += '<image preserveAspectRatio="none" lookId="' + i + '" style="display:none" clip-path="url(#myClip_' + ranId + ')" width="100%" height="100%" xlink:href="" />';
         }
      }

      svgStr += '</svg>';

      parentElement.append(svgStr);
      svgElement = $(parentElement.find("svg")[0]);
      svgPath = $(parentElement.find(".pathSVG")[0]);
      pathSVGWhite = $(parentElement.find(".pathSVGWhite")[0]);
      //-------------------------------------------------------------------
      pathSVGWhite.attr({
         "stroke-width": whiteBorderWidth
      });
      //-----------------------------------------------------------------
      imagesTagArr = svgElement.find("image");
      imagesTagArr.each(function (index, image) {
         imagesObjArr.push({
            img: $(image),
            lookId: index,
            isImgLoaed: false
         });
      });
      var tempInc = 0;
      imagesTagArr.load(function () {
         var Image = $(this);
         imagesObjArr[tempInc].isImgLoaed = true;
         tempInc++;
         This.resizeSVG();
         if (tempInc >= imagesTagArr.length) {
            return;
         }
         var nextLoadImg = $(imagesTagArr[tempInc]);
         var tempArr = orgImg.attr('src').split("/");
         var imgName = tempArr[tempArr.length - 1].split(".")[0];
         var lookId = nextLoadImg.attr('lookId');
         var newImgName = imgName + "-" + lookId + ".jpg";
         tempArr.pop();
         var path = tempArr.join("/");
         var newImgUrl = path + "/" + newImgName;
         $(imagesTagArr[tempInc]).attr({
            "xlink:href": newImgUrl
         });
      });
      //--------------------------------------------------------------

      svgElement.css({
         position: "absolute"
      });



      particles = [];

      svgWidth = parentElement.width();
      svgHeigth = parentElement.height();
      svgHalfWH = svgWidth / 2;

      var segDegree = 360 / segment;
      for (var i = 0; i < segment; i++) {
         var dg = segDegree * i;
         var rd = dg * Math.PI / 180;
         var ax = ((svgWidth - gutter * 2) / 2) * Math.cos(rd);
         var ay = ((svgHeigth - gutter * 2) / 2) * Math.sin(rd);

         var obj = new Object();
         obj.ox = obj.x = ax + svgWidth / 2;
         obj.oy = obj.y = ay + svgHeigth / 2;
         obj.isMoueIn = false;
         particles.push(obj);
         moveRandom(obj);
      }
      randomMoveArea = distanceBetween(particles[0], particles[1]) / randomIntencity;



      $('html').bind("mousemove touchmove", MouseMove);
      $(window).bind('resize', this.resizeSVG);
      this.resizeSVG();
      //requestAnimationFrame(TimeUpdate);
      waterBubbleTimeUpdateArray.push(TimeUpdate);
   };


   this.setProperty = function (paramObj) {
      gutter = paramObj.gutter != undefined ? paramObj.gutter : gutter;
      randomIntencity = paramObj.randomIntencity != undefined ? paramObj.randomIntencity : randomIntencity;
      randomMoveSpeed = paramObj.randomMoveSpeed != undefined ? paramObj.randomMoveSpeed : randomMoveSpeed;
      mouseRadius = paramObj.mouseRadius != undefined ? paramObj.mouseRadius : mouseRadius;
      isMoveOnlyOver = paramObj.isMoveOnlyOver != undefined ? paramObj.isMoveOnlyOver : isMoveOnlyOver;
      this.resizeSVG();
   };

   function TimeUpdate(e) {
      if (isDestroy) {
         return;
      }
      if (isRender == false) {
         //requestAnimationFrame(TimeUpdate);
         return;
      }
      //--------- check below code if obj is out of window then return ----------------------------
      var tempY = getPosition(parentElement[0]).y;
      var innerHeight = $(window).innerHeight();
      if ((tempY < 0 || tempY > innerHeight) && isMoveOnlyOver == false) {
         //requestAnimationFrame(TimeUpdate);
         isInWindow = false;
         return;
      }
      //-----------------------------------------------------
      isInWindow = true;
      updateDgree();
      renderSVG();
      //requestAnimationFrame(TimeUpdate);
   }

   function renderSVG() {
      var p0 = particles[0];
      var p1 = particles[1];
      svgDString = "M" + ((p0.x + p1.x) * 0.5) + "," + ((p0.y + p1.y) * 0.5);
      for (var i = 0, part = particles.length; i <= part; ++i) {
         var p0 = particles[i + 0 >= part ? i + 0 - part : i + 0];
         var p1 = particles[i + 1 >= part ? i + 1 - part : i + 1];
         svgDString += " Q" + p0.x + "," + p0.y + " " + (p0.x + p1.x) * 0.5 + "," + (p0.y + p1.y) * 0.5;
      }
      svgPath.attr("d", svgDString);

      if (isWhiteBorderActive) {
         TweenMax.set(pathSVGWhite, {
            display: "inline"
         });

         pathSVGWhite.attr("d", svgDString);
      } else {
         TweenMax.set(pathSVGWhite, {
            display: "none"
         });
      }


   }

   function moveAccToMouse(obj) {

      //mouseRadius
      //var n
      var dx = obj.ox - mouseObj.x;
      var dy = obj.oy - mouseObj.y;
      var dis = Math.sqrt(dx * dx + dy * dy);
      var x, y;
      if (dis < mouseRadius) {
         var moveArea = mouseRadius - dis;
         var angle = Math.atan2(dy, dx);
         var degrees = 180 * angle / Math.PI;

         var radian_d = degrees * Math.PI / 180;
         var arc_x = moveArea * Math.cos(radian_d);
         var arc_y = moveArea * Math.sin(radian_d);
         x = obj.ox + arc_x;
         y = obj.oy + arc_y;

         obj.isMoueIn = true;

         TweenMax.killTweensOf(obj);
         TweenMax.to(obj, 1, {
            x: x,
            y: y,
            ease: Elastic.easeOut.config(1, 0.2),
            onUpdate: function () {
               isRender = true;
            }
         });
      } else {
         if (obj.isMoueIn == true) {
            obj.isMoueIn = false;
            TweenMax.killTweensOf(obj);
            TweenMax.to(obj, 4, {
               x: obj.ox,
               y: obj.oy,
               ease: Elastic.easeOut.config(2.5, 0.1),
               onUpdate: function () {
                  isRender = true;
               },
               onComplete: moveRandom
            });
         }
      }
   }

   function moveRandom(obj) {
      var obj = obj ? obj : this.target;
      TweenMax.killTweensOf(obj);
      var rx = obj.ox + Math.random() * randomMoveArea - randomMoveArea / 2;
      var ry = obj.oy + Math.random() * randomMoveArea - randomMoveArea / 2;
      TweenMax.killTweensOf(obj);
      TweenMax.to(obj, randomMoveSpeed, {
         x: rx,
         y: ry,
         delay: Math.random() * randomMoveSpeed,
         ease: Power1.easeInOut,
         onUpdate: function () {
            isRender = true;
         },
         onComplete: function () {
            if (isMoveOnlyOver == true) {
               isRender = false;
            } else {
               moveRandom(obj);
            }
         }
      });
   }

   function getPosition(element) {
      var xPosition = 0;
      var yPosition = 0;
      while (element) {
         xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
         yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
         element = element.offsetParent;
      }
      return {
         x: xPosition,
         y: yPosition
      };
   }

   function updateDgree() {
      if (is360 == false) {
         return;
      }
      lookObj.degree;


      var dx = mouseObj.x - svgHalfWH;
      var dy = mouseObj.y - svgHalfWH;
      //---------overwite fish look with mouse cursor -------------------------------------------
      if (window.svgFishAnimClass) {
         if (svgFishAnimClass.svgTranform.x) {
            var offset = getPosition(parentElement[0]);
            var mX = svgFishAnimClass.svgTranform.x - offset.x + tempExtraGutter / 2;
            var mY = svgFishAnimClass.svgTranform.y - offset.y + tempExtraGutter / 2;
            var dx = mX - svgHalfWH;
            var dy = mY - svgHalfWH;
         }
      }
      //-------------------------------------------------------------
      var tempScaleX = dx / 700;
      tempScaleX = tempScaleX <= 1 ? 1 : tempScaleX;
      dy = dy * tempScaleX;
      var dis = Math.sqrt(dx * dx + dy * dy);
      var angle = Math.atan2(dy, dx);
      var degrees = 180 * angle / Math.PI;
      var degreesPositive = degrees < 0 ? 360 + degrees : degrees;
      degreesPositive = (degreesPositive + eachLookInDegree / 2) % 360;
      var curLookId = Math.ceil(degreesPositive / eachLookInDegree);
      var isFound = false;
      var imageObj;
      //
      var imageObj = imagesObjArr[curLookId];
      if (imageObj.isImgLoaed == false || dis < svgHalfWH) {
         imageObj = imagesObjArr[0];
      }
      imagesTagArr.css({
         display: "none"
      })
      imageObj.img.css({
         display: "block"
      });
   }

   function MouseMove(e) {
      if (isInWindow == false) {
         return;
      }
      if (e.pageX) {
         mouseObj.x = e.pageX;
         mouseObj.y = e.pageY;
      } else {
         if (e.originalEvent.targetTouches) {
            mouseObj.x = e.originalEvent.targetTouches[0].pageX;
            mouseObj.y = e.originalEvent.targetTouches[0].pageY;
         }
      }
      var offset = getPosition(parentElement[0]);
      mouseObj.x = mouseObj.x - offset.x + tempExtraGutter / 2;
      mouseObj.y = mouseObj.y - offset.y - $(window).scrollTop() + tempExtraGutter / 2;


      if (is360) {
         updateDgree();
      }
      /*var offset  = svgElement.offset();
      mouseObj.x = mouseObj.x - offset.left;
      mouseObj.y = mouseObj.y - offset.top;*/

      for (var i = 0; i < particles.length; i++) {
         moveAccToMouse(particles[i]);
      }
   }
   this.destroy = function () {
      if (Modernizr.svg && Modernizr.requestanimationframe) {} else {
         return;
      }
      $('html').unbind("mousemove touchmove", MouseMove);
      $(window).unbind('resize', this.resizeSVG);
      isDestroy = true;
   };
   this.resizeSVG = function (e) {
      if (Modernizr.svg && Modernizr.requestanimationframe) {} else {
         return;
      }
      var orgImg = parentElement.find('.bubbleOrgImg');
      parentElement.css({
         'height': orgImg.height() + 'px'
      });

      tempExtraGutter = gutter * 2.5;
      svgWidth = parentElement.width() + tempExtraGutter;
      svgHeigth = parentElement.height() + tempExtraGutter;
      svgHalfWH = svgWidth / 2;
      svgElement.attr({
         x: -tempExtraGutter / 2,
         y: -tempExtraGutter / 2,
         width: svgWidth,
         height: svgHeigth
      })
      svgElement.css({
         left: -tempExtraGutter / 2,
         top: -tempExtraGutter / 2
      })

      var segDegree = 360 / segment;
      for (var i = 0; i < segment; i++) {
         var dg = segDegree * i;
         var rd = dg * Math.PI / 180;
         var ax = ((svgWidth - gutter * 2) / 2) * Math.cos(rd);
         var ay = ((svgHeigth - gutter * 2) / 2) * Math.sin(rd);

         var obj = particles[i];
         obj.ox = obj.x = ax + svgWidth / 2;
         obj.oy = obj.y = ay + svgHeigth / 2;
         obj.isMoueIn = false;
         moveRandom(obj);
      }
      randomMoveArea = distanceBetween(particles[0], particles[1]) / randomIntencity;

   };

   function distanceBetween(p1, p2) {
      var dx = p2.x - p1.x;
      var dy = p2.y - p1.y;
      return Math.sqrt(dx * dx + dy * dy);
   }
}
+function ($) {
	var innerHeight;
	var isInViewport = function (elem) {
		if(elem.getBoundingClientRect){
			var bounding = elem.getBoundingClientRect();
				//console.log((bounding.top >= -bounding.height && bounding.top <= innerHeight));
			return (
					bounding.top >= -bounding.height && bounding.top <= innerHeight
			);

		}else{
			return true;
		}
		
	};
	var $window = $(window); // There is only one window, so why not cache the jQuery-wrapped window?

	String.prototype.endsWith = function (suffix) {
		return this.indexOf(suffix, this.length - suffix.length) !== -1;
	}; // Stupid Chrome

	function hasWebGLSupport() {
		var canvas = document.createElement('canvas');
		var context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
		var result = context && context.getExtension('OES_texture_float') && context.getExtension('OES_texture_float_linear');
		if (canvas.remove) {
			canvas.remove();
		}
		//
		return result;
	}

	var supportsWebGL = hasWebGLSupport();

	function createProgram(gl, vertexSource, fragmentSource, uniformValues) {
		function compileSource(type, source) {
			var shader = gl.createShader(type);
			gl.shaderSource(shader, source);
			gl.compileShader(shader);
			if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
				throw new Error('compile error: ' + gl.getShaderInfoLog(shader));
			}
			return shader;
		}

		var program = {};

		program.id = gl.createProgram();
		gl.attachShader(program.id, compileSource(gl.VERTEX_SHADER, vertexSource));
		gl.attachShader(program.id, compileSource(gl.FRAGMENT_SHADER, fragmentSource));
		gl.linkProgram(program.id);
		if (!gl.getProgramParameter(program.id, gl.LINK_STATUS)) {
			throw new Error('link error: ' + gl.getProgramInfoLog(program.id));
		}

		// Fetch the uniform and attribute locations
		program.uniforms = {};
		program.locations = {};
		gl.useProgram(program.id);
		gl.enableVertexAttribArray(0);
		var name, type, regex = /uniform (\w+) (\w+)/g, shaderCode = vertexSource + fragmentSource;
		while ((match = regex.exec(shaderCode)) != null) {
			name = match[2];
			program.locations[name] = gl.getUniformLocation(program.id, name);
		}

		return program;
	}	

	function bindTexture(gl, texture, unit) {
		gl.activeTexture(gl.TEXTURE0 + (unit || 0));
		gl.bindTexture(gl.TEXTURE_2D, texture);
	}

	// RIPPLES CLASS DEFINITION
	// =========================

	var Ripples = function (el, options) {
		var that = this;
		this.gl;
		this.$el = $(el);
		this.$el.addClass('ripples');
		this.infoData = $(el).data('info');
		this.parentElement = that.$el;
		innerHeight = $(window).innerHeight();

		this.radius = 0.03;
		if (this.infoData && this.infoData.radius) {
			this.radius = this.infoData.radius;
		}


		this.strength = 0.01;
		if (this.infoData && this.infoData.strength) {
			this.strength = this.infoData.strength;
		}

		this.intRippleCall = function (paramObj) {
			if (that.infoData && that.infoData.isInitDrop) {
				var newObj = {
					isCenterDrop: true
				}
				if (paramObj){
					for (var key in paramObj) {
						if (paramObj.hasOwnProperty(key)) {
							newObj[key] = paramObj[key];							
						}
					}
				}

				that.dropAtMouse(newObj, that.infoData.isInitDrop.radius, that.infoData.isInitDrop.strength);
			}
		}

		$(el).data('intRippleCall',this.intRippleCall);


		// If this element doesn't have a background image, don't apply this effect to it
		var backgroundUrl = (/url\(["']?([^"']*)["']?\)/.exec(this.$el.css('background-image')));
		if (backgroundUrl == null) return;
		backgroundUrl = backgroundUrl[1];

		this.resolution = options.resolution || 256;
		this.textureDelta = new Float32Array([1 / this.resolution, 1 / this.resolution]);

		this.perturbance = options.perturbance;

		var canvas = document.createElement('canvas');
		canvas.width = this.$el.outerWidth();
		canvas.height = this.$el.outerHeight();
		this.canvas = canvas;

		this.$el.append(canvas);
		this.context = this.gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

		// Load extensions
		this.gl.getExtension('OES_texture_float');
		this.gl.getExtension('OES_texture_float_linear');

		// Init events
		$(window).on('resize', function () {
			innerHeight = $(window).innerHeight();
			if (that.$el.outerWidth() != that.canvas.width || that.$el.outerHeight() != that.canvas.height) {
				canvas.width = that.$el.outerWidth();
				canvas.height = that.$el.outerHeight();
			}
		});
		this.$el.on('mousemove', $.proxy(this.mousemove, this));
		this.$el.on('mousemoverel', $.proxy(this.mousemove, this));
		this.$el.on('mousedown', $.proxy(this.mousedown, this));

		// Init textures

		var image = new Image;
		image.crossOrigin = '';
		image.onload = function () {
			that.gl = that.context;

			function isPowerOfTwo(x) {
				return (x & (x - 1)) == 0;
			}

			var wrapping = (isPowerOfTwo(image.width) && isPowerOfTwo(image.height)) ? that.gl.REPEAT : that.gl.CLAMP_TO_EDGE;

			that.backgroundWidth = image.width;
			that.backgroundHeight = image.height;

			var texture = that.gl.createTexture();

			that.gl.bindTexture(that.gl.TEXTURE_2D, texture);
			that.gl.pixelStorei(that.gl.UNPACK_FLIP_Y_WEBGL, 1);
			that.gl.texParameteri(that.gl.TEXTURE_2D, that.gl.TEXTURE_MAG_FILTER, that.gl.LINEAR);
			that.gl.texParameteri(that.gl.TEXTURE_2D, that.gl.TEXTURE_MIN_FILTER, that.gl.LINEAR);
			that.gl.texParameteri(that.gl.TEXTURE_2D, that.gl.TEXTURE_WRAP_S, wrapping);
			that.gl.texParameteri(that.gl.TEXTURE_2D, that.gl.TEXTURE_WRAP_T, wrapping);
			that.gl.texImage2D(that.gl.TEXTURE_2D, 0, that.gl.RGBA, that.gl.RGBA, that.gl.UNSIGNED_BYTE, image);

			that.backgroundTexture = texture;
			$(el).css({ 'background-image': 'none' });
			that.intRippleCall();

		};
		image.src = backgroundUrl;

		this.textures = [];
		this.framebuffers = [];

		for (var i = 0; i < 2; i++) {
			var texture = this.gl.createTexture();
			var framebuffer = this.gl.createFramebuffer();

			this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, framebuffer);
			framebuffer.width = this.resolution;
			framebuffer.height = this.resolution;

			this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
			this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.resolution, this.resolution, 0, this.gl.RGBA, this.gl.FLOAT, null);

			var renderbuffer = this.gl.createRenderbuffer();
			this.gl.bindRenderbuffer(this.gl.RENDERBUFFER, renderbuffer);
			this.gl.renderbufferStorage(this.gl.RENDERBUFFER, this.gl.DEPTH_COMPONENT16, this.resolution, this.resolution);

			this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D, texture, 0);
			this.gl.framebufferRenderbuffer(this.gl.FRAMEBUFFER, this.gl.DEPTH_ATTACHMENT, this.gl.RENDERBUFFER, renderbuffer);
			if (this.gl.checkFramebufferStatus(this.gl.FRAMEBUFFER) != this.gl.FRAMEBUFFER_COMPLETE) {
				throw new Error('Rendering to this texture is not supported (incomplete framebuffer)');
			}

			this.gl.bindTexture(this.gl.TEXTURE_2D, null);
			this.gl.bindRenderbuffer(this.gl.RENDERBUFFER, null);
			this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

			this.textures.push(texture);
			this.framebuffers.push(framebuffer);
		}

		// Init GL stuff
		this.quad = this.gl.createBuffer();
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quad);
		this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array([
			-1, -1,
			+1, -1,
			+1, +1,
			-1, +1
		]), this.gl.STATIC_DRAW);

		this.initShaders();

		// Init animation
		function step() {
			that.update();
			requestAnimationFrame(step);
		}
		requestAnimationFrame(step);
	};

	Ripples.DEFAULTS = {
		resolution: 256,
		perturbance: 0.03
	};

	Ripples.prototype = {
		update: function () {
			//console.log(parentElement[0])
			if (isInViewport(this.parentElement[0])) {
			}else{
				return;
			}
			gl = this.context;

			if (!this.backgroundTexture) return;

			this.updateTextures();
			this.render();
		},

		drawQuad: function () {
			this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quad);
			this.gl.vertexAttribPointer(0, 2, this.gl.FLOAT, false, 0, 0);
			this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, 4);
		},

		render: function () {
			this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
			this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

			this.gl.useProgram(this.renderProgram.id);

			bindTexture(this.gl, this.backgroundTexture, 0);
			bindTexture(this.gl, this.textures[0], 1);

			this.gl.uniform2fv(this.renderProgram.locations.topLeft, this.renderProgram.uniforms.topLeft);
			this.gl.uniform2fv(this.renderProgram.locations.bottomRight, this.renderProgram.uniforms.bottomRight);
			this.gl.uniform2fv(this.renderProgram.locations.containerRatio, this.renderProgram.uniforms.containerRatio);
			this.gl.uniform1i(this.renderProgram.locations.samplerBackground, 0);
			this.gl.uniform1i(this.renderProgram.locations.samplerRipples, 1);

			this.drawQuad();
		},

		updateTextures: function () {
			this.computeTextureBoundaries();

			this.gl.viewport(0, 0, this.resolution, this.resolution);

			for (var i = 0; i < 2; i++) {
				this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.framebuffers[i]);
				bindTexture(this.gl, this.textures[1 - i]);
				this.gl.useProgram(this.updateProgram[i].id);

				this.drawQuad();
			}

			this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
		},

		computeTextureBoundaries: function () {
			var backgroundSize = this.$el.css('background-size');
			var backgroundAttachment = this.$el.css('background-attachment');
			var backgroundPosition = this.$el.css('background-position').split(' ');

			// Here the 'window' is the element which the background adapts to 
			// (either the chrome window or some element, depending on attachment)
			var parElement = backgroundAttachment == 'fixed' ? $window : this.$el;
			var winOffset = parElement.offset() || { left: pageXOffset, top: pageYOffset };
			var winWidth = parElement.outerWidth();
			var winHeight = parElement.outerHeight();

			// TODO: background-clip
			if (backgroundSize == 'cover') {
				var scale = Math.max(winWidth / this.backgroundWidth, winHeight / this.backgroundHeight);

				var backgroundWidth = this.backgroundWidth * scale;
				var backgroundHeight = this.backgroundHeight * scale;
			}
			else if (backgroundSize == 'contain') {
				var scale = Math.min(winWidth / this.backgroundWidth, winHeight / this.backgroundHeight);

				var backgroundWidth = this.backgroundWidth * scale;
				var backgroundHeight = this.backgroundHeight * scale;
			}
			else {
				backgroundSize = backgroundSize.split(' ');
				var backgroundWidth = backgroundSize[0];
				var backgroundHeight = backgroundSize[1] || backgroundSize[0];

				if (backgroundWidth.endsWith('%')) backgroundWidth = winWidth * parseFloat(backgroundWidth) / 100;
				else if (backgroundWidth == 'auto') backgroundWidth = this.backgroundWidth;
				else backgroundWidth = parseFloat(backgroundWidth);

				if (backgroundHeight.endsWith('%')) backgroundHeight = winHeight * parseFloat(backgroundHeight) / 100;
				else if (backgroundHeight == 'auto') backgroundHeight = this.backgroundHeight;
				else backgroundHeight = parseFloat(backgroundHeight);
			}

			// Compute backgroundX and backgroundY in page coordinates
			var backgroundX = backgroundPosition[0];
			var backgroundY = backgroundPosition[1];

			if (backgroundX == 'left') backgroundX = winOffset.left;
			else if (backgroundX == 'center') backgroundX = winOffset.left + winWidth / 2 - backgroundWidth / 2;
			else if (backgroundX == 'right') backgroundX = winOffset.left + winWidth - backgroundWidth;
			else if (backgroundX.endsWith('%')) {
				backgroundX = winOffset.left + (winWidth - backgroundWidth) * parseFloat(backgroundX) / 100;
			}
			else {
				backgroundX = parseFloat(backgroundX);
			}

			if (backgroundY == 'top') backgroundY = winOffset.top;
			else if (backgroundY == 'center') backgroundY = winOffset.top + winHeight / 2 - backgroundHeight / 2;
			else if (backgroundY == 'bottom') backgroundY = winOffset.top + winHeight - backgroundHeight;
			else if (backgroundY.endsWith('%')) {
				backgroundY = winOffset.top + (winHeight - backgroundHeight) * parseFloat(backgroundY) / 100;
			}
			else {
				backgroundY = parseFloat(backgroundY);
			}

			var elementOffset = this.$el.offset();

			this.renderProgram.uniforms.topLeft = new Float32Array([
				(elementOffset.left - backgroundX) / backgroundWidth,
				(elementOffset.top - backgroundY) / backgroundHeight
			]);
			this.renderProgram.uniforms.bottomRight = new Float32Array([
				this.renderProgram.uniforms.topLeft[0] + this.$el.outerWidth() / backgroundWidth,
				this.renderProgram.uniforms.topLeft[1] + this.$el.outerHeight() / backgroundHeight
			]);

			var maxSide = Math.max(this.canvas.width, this.canvas.height);

			this.renderProgram.uniforms.containerRatio = new Float32Array([
				this.canvas.width / maxSide,
				this.canvas.height / maxSide
			]);
		},

		initShaders: function () {
			var vertexShader = [
				'attribute vec2 vertex;',
				'varying vec2 coord;',
				'void main() {',
				'coord = vertex * 0.5 + 0.5;',
				'gl_Position = vec4(vertex, 0.0, 1.0);',
				'}'
			].join('\n');

			this.dropProgram = createProgram(this.gl, vertexShader, [
				'precision highp float;',

				'const float PI = 3.141592653589793;',
				'uniform sampler2D texture;',
				'uniform vec2 center;',
				'uniform float radius;',
				'uniform float strength;',

				'varying vec2 coord;',

				'void main() {',
				'vec4 info = texture2D(texture, coord);',

				'float drop = max(0.0, 1.0 - length(center * 0.5 + 0.5 - coord) / radius);',
				'drop = 0.5 - cos(drop * PI) * 0.5;',

				'info.r += drop * strength;',

				'gl_FragColor = info;',
				'}'
			].join('\n'));

			this.updateProgram = [0, 0];
			this.updateProgram[0] = createProgram(this.gl, vertexShader, [
				'precision highp float;',

				'uniform sampler2D texture;',
				'uniform vec2 delta;',

				'varying vec2 coord;',

				'void main() {',
				'vec4 info = texture2D(texture, coord);',

				'vec2 dx = vec2(delta.x, 0.0);',
				'vec2 dy = vec2(0.0, delta.y);',

				'float average = (',
				'texture2D(texture, coord - dx).r +',
				'texture2D(texture, coord - dy).r +',
				'texture2D(texture, coord + dx).r +',
				'texture2D(texture, coord + dy).r',
				') * 0.25;',

				'info.g += (average - info.r) * 2.0;',
				'info.g *= 0.995;',
				'info.r += info.g;',

				'gl_FragColor = info;',
				'}'
			].join('\n'));
			this.gl.uniform2fv(this.updateProgram[0].locations.delta, this.textureDelta);

			this.updateProgram[1] = createProgram(this.gl, vertexShader, [
				'precision highp float;',

				'uniform sampler2D texture;',
				'uniform vec2 delta;',

				'varying vec2 coord;',

				'void main() {',
				'vec4 info = texture2D(texture, coord);',

				'vec3 dx = vec3(delta.x, texture2D(texture, vec2(coord.x + delta.x, coord.y)).r - info.r, 0.0);',
				'vec3 dy = vec3(0.0, texture2D(texture, vec2(coord.x, coord.y + delta.y)).r - info.r, delta.y);',
				'info.ba = normalize(cross(dy, dx)).xz;',

				'gl_FragColor = info;',
				'}'
			].join('\n'));
			this.gl.uniform2fv(this.updateProgram[1].locations.delta, this.textureDelta);

			this.renderProgram = createProgram(this.gl, [
				'precision highp float;',

				'attribute vec2 vertex;',
				'uniform vec2 topLeft;',
				'uniform vec2 bottomRight;',
				'uniform vec2 containerRatio;',
				'varying vec2 ripplesCoord;',
				'varying vec2 backgroundCoord;',
				'void main() {',
				'backgroundCoord = mix(topLeft, bottomRight, vertex * 0.5 + 0.5);',
				'backgroundCoord.y = 1.0 - backgroundCoord.y;',
				'ripplesCoord = vec2(vertex.x, -vertex.y) * containerRatio * 0.5 + 0.5;',
				'gl_Position = vec4(vertex.x, -vertex.y, 0.0, 1.0);',
				'}'
			].join('\n'), [
				'precision highp float;',

				'uniform sampler2D samplerBackground;',
				'uniform sampler2D samplerRipples;',
				'uniform float perturbance;',
				'varying vec2 ripplesCoord;',
				'varying vec2 backgroundCoord;',

				'void main() {',
				'vec2 offset = -texture2D(samplerRipples, ripplesCoord).ba;',
				'float specular = pow(max(0.0, dot(offset, normalize(vec2(-0.6, 1.0)))), 4.0);',
				'gl_FragColor = texture2D(samplerBackground, backgroundCoord + offset * perturbance) + specular;',
				'}'
			].join('\n'));
			this.gl.uniform1f(this.renderProgram.locations.perturbance, this.perturbance);
		},

		dropAtMouse: function (e, radius, strength) {
			var that = this;

			gl = this.context;

			e.offsetX = e.offsetX || (e.pageX - this.$el.offset().left);
			e.offsetY = e.offsetY || (e.pageY - this.$el.offset().top);

			var elWidth = this.$el.outerWidth();
			var elHeight = this.$el.outerHeight();
			if (e.isCenterDrop === true) {
				if (e.xpos && e.ypos){
					e.offsetX = e.xpos;
					e.offsetY = e.ypos;
				}else{
					e.offsetX = elWidth / 2;
					e.offsetY = elHeight / 2;
				}
			}
			var longestSide = Math.max(elWidth, elHeight);

			var dropPosition = new Float32Array([
				(2 * e.offsetX - elWidth) / longestSide,
				(elHeight - 2 * e.offsetY) / longestSide
			]);

			this.gl.viewport(0, 0, this.resolution, this.resolution);

			// Render onto texture/framebuffer 0
			this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.framebuffers[0]);

			// Using texture 1
			bindTexture(this.gl, this.textures[1]);

			this.gl.useProgram(this.dropProgram.id);
			this.gl.uniform2fv(this.dropProgram.locations.center, dropPosition);
			this.gl.uniform1f(this.dropProgram.locations.radius, radius);
			this.gl.uniform1f(this.dropProgram.locations.strength, strength);

			this.drawQuad();

			// Switch textures
			var t = this.framebuffers[0]; this.framebuffers[0] = this.framebuffers[1]; this.framebuffers[1] = t;
			t = this.textures[0]; this.textures[0] = this.textures[1]; this.textures[1] = t;

			this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
		},

		mousemove: function (e) {
			this.dropAtMouse(e, this.radius, this.strength);
		},

		mousedown: function (e) {
			this.dropAtMouse(e, this.radius, this.strength);
		},
	};

	// RIPPLES PLUGIN DEFINITION
	// ==========================

	var old = $.fn.ripples;

	$.fn.ripples = function (option) {
		return this.each(function () {
			if (!supportsWebGL) throw new Error('Your browser does not support at least one of the following: WebGL, OES_texture_float extension, OES_texture_float_linear extension.');
			var $this = $(this);
			var data = $this.data('ripples');
			var options = $.extend({}, Ripples.DEFAULTS, $this.data(), typeof option == 'object' && option);

			if (!data)
				$this.data('ripples', new Ripples(this, options));
		});
	}

	$.fn.ripples.Constructor = Ripples;


	// RIPPLES NO CONFLICT
	// ====================

	$.fn.ripples.noConflict = function () {
		$.fn.ripples = old;
		return this;
	}

}(window.jQuery);
var isMobile = new Object();
isMobile.Android = function() {
  return navigator.userAgent.match(/Android/i);
};
isMobile.BlackBerry = function() {
  return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};
isMobile.iPad = function() {
  return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function() {
  return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function() {
  return navigator.userAgent.match(/IEMobile/i);
};
isMobile.Firefox = function() {
  return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function() {
  return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function() {
  return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function() {
  return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function() {
  return (
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows()
  );
};
if (isMobile.any() && isMobile.iPad() == null) {
  //return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
var winWT = $(window).innerWidth();
$(document).ready(function() {
  //Modernizr.svg = false;
  var resStartWT = 1100;
  //var wave = new Wave();
  //wave.initialize();

  
  $(".slimmenu").slimmenu({
    resizeWidth: resStartWT,
    collapserTitle: "",
    animSpeed: "medium",
    indentChildren: true,
    childrenIndenter: "&raquo;"
  });

  $("#nav-icon1").click(function() {
    if ($("#nav-icon1").hasClass("open")) {
      $(this).removeClass("open");
      $(".collapsed").slideUp(300);
    } else {
      $(this).addClass("open");
      $(".collapsed").slideDown(300);
    }
  });

  $(window).resize(function() {
    $("#nav-icon1").removeClass("open");
  });

  //TweenLite.to("header", 1, {className:"+=stickyHeaderDesk", ease: Sine.easeOut});
  function menuAnimationDesk() {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 150) {
        $("header").addClass("stickyHeaderDesk");
      } else {
        $("header").removeClass("stickyHeaderDesk");
      }
    });
  }

  function menuAnimationMob() {
    $(window).scroll(function() {
      //$(".collapsed").slideUp(300);
      //$(this).removeClass("open");
      if ($(this).scrollTop() > 80) {
        $("header").addClass("stickyHeaderDesk");
      } else {
        $("header").removeClass("stickyHeaderDesk");
      }
    });
  }

  if (winWT > resStartWT) {
    menuAnimationDesk();
  } else {
    menuAnimationMob();
  }

  function setRippleEffect() {
    if(isMobile.any() || is_ie){
      return;
    }
    function hasWebGLSupport() {
      var canvas = document.createElement("canvas");
      var context =
        canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      var result =
        context &&
        context.getExtension("OES_texture_float") &&
        context.getExtension("OES_texture_float_linear");
      if (canvas.remove) {
        canvas.remove();
      }
      //
      return result;
    }

    var supportsWebGL = hasWebGLSupport();
    if (supportsWebGL) {
    } else {
      return;
    }

    /*$(".ripple_holder").ripples({
            resolution: 256,
            perturbance: 0.01,
        });*/
    $(".ripple_holder").each(function() {
      var ele = $(this);
      ele.ripples({
        resolution: 256,
        perturbance: 0.01
      });

      var data = $(this).data();
      ele.waypoint(
        function() {
          if (data.intRippleCall) {
            data.intRippleCall();
          }
        },
        { offset: data.offset }
      );
    });

    function setFooterRipple() {
      var footerData = $(".footerWaveImg").data();
      var count = 0;
      $("body").bind("mousemove", function(e) {
        count++;
        if (count >= 6) {
          count = 0;
          e.offsetX = e.offsetX || e.pageX;
          e.offsetY = e.offsetY || e.pageY;
          footerData.intRippleCall({
            xpos: e.offsetX,
            ypos: -100
          });
        }
      });
    }
    setFooterRipple();
  }



  setRippleEffect();



  /****Free Demo*****/

  function freeDemoFormDesktop(){
    $('.free_demo').bind('click', function(){
      TweenMax.to(".demoForm",0.5, {left:0, ease: Sine.easeOut});
    });
    $('.close_form').bind('click', function(){
      TweenMax.to(".demoForm",0.5, {left:'-280px', ease: Sine.easeOut});
    });
  }



  

  if(winWT > 1100){
    freeDemoFormDesktop();
  }else{
    /*var sectRef = $('.free_demo').remove();
    $('.socialSect').append(sectRef);
    sectRef.insertBefore('.chatIcon');
    console.log(sectRef);*/
    var socialIcon = $('.social_links').remove();
    var formBtn = $('.free_demo').remove();
    var chatIcon = $('.chatIcon').remove();
    var socialCon = $('.socialCon').remove();
    $('.resStickySocial').append(socialIcon);
    $('.resStickySocial').append(chatIcon);
    $('.resStickySocial').append(formBtn);
    $('.resStickySocial').append(socialCon);

    
    TweenMax.set('.soc_links_con', { left:'-50px' })

    $('.social_like').bind('click', function(){
      //console.log('asd');
      var socLinkArr = $(this).find('.soc_links_con');
      if($(this).hasClass('open_social')){
        //$(socLinkArr).css({'display':'none'});
        TweenMax.to('.soc_links_con', 0.5, { left:'-50px', ease: Sine.easeOut})
        $(this).removeClass('open_social');
      }else{
        //$(socLinkArr).css({'display':'block'});
        TweenMax.to('.soc_links_con', 0.5, { left:0, ease: Sine.easeOut})
        $(this).addClass('open_social');
      }
    });

    $('.free_demo').bind('click', function(){
      $('.socialCon').css({'display':'block'});
    });

    $('.close_form').bind('click', function(){
      $('.socialCon').css({'display':'none'});
    });

    $('.chatIcon').bind('click', function(){
      $('.socialCon').css({'display':'none'});
    });
    

  }

});
